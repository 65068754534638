import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Col } from "reactstrap";

class PricingBox extends Component {

    render() {
        const responsiveClass = this.props.pricing.isRibbon ? "" : "d-none .d-sm-block d-md-block d-lg-block d-xl-block"
        const className = "text-center pricing-box bg-white price-active " + responsiveClass
        return (
            <React.Fragment>
                <Col lg="4">
                    <div className={className}>
                        {
                            this.props.pricing.isRibbon
                            ? <div className="ribbon-box"><span>Popular</span></div>
                            : null
                        }
                        <h4 style={{textAlign: 'right', fontSize: this.props.pricing.priceFontSize}}>{this.props.pricing.price}</h4>
                        <h3 style={{fontSize: this.props.pricing.titleFontSize}} className="text-uppercase">{this.props.pricing.title}</h3>
                        <div className="plan-features margin-t-50">
                            <p>Party: <b className="text-custom">{this.props.pricing.features.size}</b></p>
                            <p>Rosette for all: <b className="text-custom">Yes</b></p>
                            <p>{this.props.noun}: <b className="text-custom">{this.props.pricing.features.unicorns}</b></p>
                            <p><b className="text-custom">{this.props.pricing.features.hiddenFees}</b> Hidden Fees</p>
                        </div>
                        <button
                            className="btn btn-custom waves-effect waves-light margin-t-30"
                            onClick={() => this.props.handleClick(this.props.pricing.to)}
                            style={{fontSize: '1.5rem'}}>
                                <img src="/assets/images/facebook.png" height="36" />
                                &nbsp;&nbsp;&nbsp;contact us
                        </button>
                    </div>
                </Col>
            </React.Fragment>
        );
    }
}

export default PricingBox;