import LandingPageChild from "./views/LandingPageChild";
import LandingPageAdult from "./views/LandingPageAdult";
import LandingPageChristmas from "./views/LandingPageChristmas";


const routes = [
  { path: "/", component: LandingPageChild },
  { path: "/unicorn-party-for-adults", component: LandingPageAdult },
  { path: "/unicorn-party-for-adults", component: LandingPageAdult },
  { path: "/christmas-reindeer-party", component: LandingPageChristmas },
];

export default routes;
