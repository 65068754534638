import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

import ReactTextRotator from 'react-text-rotator';


export function Section(props) {
    return (
        <section className="section section-lg pt-10" id="home">
            <div className="bg-overlay"></div>
            <div className="display-table pt-5">
                <div className="display-table-cell">
                    <Container>
                        <Row>
                            <Col lg={{size:8, offset:2}} className="text-white text-center">
                                <h1 className="mx-auto margin-b-30">We bring magical rainbow {props.noun} to your {props.event} in <strong>Lincolnshire</strong> and <strong>Nottinghamshire</strong> from only £99.</h1>
                                <br />
                                <br />
                                <h1 className="home-title text-rotate">
                                    <ReactTextRotator
                                        content={props.ledes}
                                        time={5000}
                                        startDelay={2000}
                                    />
                                </h1>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </section>
    );
}

export default Section;