import React, { Component } from 'react';
import { Container, Row, Button } from "reactstrap";

import SectionTitle from "../common/section-title";

class Testimonials extends Component {
    
    render() {
        return (
            <React.Fragment>
                <section className="section" id="testi">
                    <Container>
                        {/* Render section title */}
                        <SectionTitle title="Happy mummies and daddies" />
                        <Row className="" style={{ alignItems: "center", justifyContent: "center"}}>
                            ⭐⭐⭐⭐⭐ 5 star rating on Facebook. Mummies, Daddies, and Kiddies love us!
                        </Row>
                        <Row className="margin-t-50" style={{ alignItems: "center", justifyContent: "center"}}>
                            <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fjemma.beal%2Fposts%2F10224531617090459&show_text=true&width=350" width="350" height="630" style={{"border": "none", "overflow":"hidden", marginRight: '20px'}} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                            <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fheather.carr.750%2Fposts%2F5032743890095651&width=350&show_text=true&appId=851890281488523&height=630" width="350" height="630" style={{"border": "none", "overflow":"hidden", marginRight: '20px'}} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                            <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Femmasnow16%2Fposts%2F10160157287450992&show_text=true&width=350" width="350" height="630" style={{"border": "none", "overflow":"hidden"}} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                        </Row>
                        <Row className="margin-t-50" style={{ alignItems: "center", justifyContent: "center"}}>
                            <a href="https://www.facebook.com/PonyTime.co.uk/reviews/">
                                <Button style={{fontSize: '22px', padding: '10px 25px 10px 25px'}} type="button" className="btn-custom navbar-btn btn-rounded waves-effect waves-light">
                                    Read more Facebook reviews
                                </Button>
                            </a>
                        </Row>

                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Testimonials;