import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap";

//Import Team Box
import TeamBox from "./team-box";
import SectionTitle from "../common/section-title";


class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            members : [
                {
                    id : 3,
                    name : props.focusPonyName,
                    image : props.focusPonySrc,
                    post : "Welsh pony",
                    style: props.focusPonyStyle,
                },
                {
                    id : 4,
                    name : "Sarah",
                    image : "assets/images/team/img-4.jpg",
                    post : "Human friend",
                    style: {filter: "brightness(1.2)"},
                },
            ]
        }
    }

    render() {
        return (
            <React.Fragment>
                <section className="section" id="about">
                    <Container>

                        <SectionTitle title="Our ponies and their friend" description={this.props.lede} />

                        <Row className="margin-t-50">
                            {/* Render Team members */}
                            {
                                this.state.members.map((member) =>
                                    <TeamBox {...member} />
                                )
                            }
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default AboutUs;
