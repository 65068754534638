import React, { useLayoutEffect } from 'react';
import NavbarPage from "../components/Navbar/NavbarPage";
import Hero from '../components/Hero/hero';
import Features from '../components/Features/features';
import Services from '../components/Services/services';
import Team from '../components/Team/Team';
import Pricing from '../components/Pricing/pricing';
import Testimonials from '../components/Testimonials/testimonials';
import ContactUs from '../components/Contact Us/contact-us';
import Footer from '../components/Footer/footer';


const images = [
  {
    original: '/assets/images/gallery/reindeer01.png',
    width: 500,
  },
  {
    original: '/assets/images/gallery/reindeer02.png',
    width: 500,
  },
];

const heroLedes = [
    {
      text: 'Have a reindeer at your Christmas party!',
      animation: 'fade',
    },
    {
      text: "“The kids can't wait to tell everyone at school!”",
      animation: 'fade',
    },
    {
      text: 'Give your child a unique unforgettable experience',
      animation: 'fade',
    },
    {
      text: "“I love how Sarah shows how to care for Glitter”",
      animation: 'fade',
    },
    {
      text: "“All the children loved it!”",
      animation: 'fade',
    },
    {
      text: "“Absolutely perfect for my 4 year old”",
      animation: 'fade',
    },
    {
      text: 'Your child can ride and play with a real reindeer',
      animation: 'fade',
    },
    {
      text: "“Recommend for dads needing extra browny points!”",
      animation: 'fade',
    }

];

const features = [
  "Actual real life tiny 4 foot tall reindeer at your party!",
  "You and your kids will remember your day for years to come",
  "Lockdown friendly: full refund for lockdown cancellations",
  "We travel to Lincolnshire and Nottinghamshire"
]

const services =  [
  { title : "Covid safe", description : "Sarah wears top quality face masks and professionally cleans the equipment before every party." },
  { title : "Personalised rosettes", description : "Remember a unique day with a Christmas reindeer rosette for every child." },
  { title : "Magical ride", description : "The smaller kids can ride on our reindeer. Bigger kids of all ages can pat them! The kiddies can colour in their rosette while they wait their turn." },
  { title : "Friendly staff", description : "Sarah will solve any problem to ensure the day goes swimmingly." },
  { title : "Safe and professional", description : "Sarah has over four decades of experience with horses, and all the paperwork" },
  { title : "Easy to customize", description : "Need something extra special done? Get in touch and we can make it happen!" },
]

const pricing = [
    {
      id: 3,
      title: "ULTIMATE Party",
      price: "£300",
      to: "birthday-ultimate",
      priceFontSize: "24px",
      titleFontSize: "18px",
      features: {
        size: "very large",
          time: "2 hours",
          maxChild: "8",
          hiddenFees: "No",
          unicorns: 2,
      }
  },
  {
      id: 2,
      title: "DELUXE Party",
      price: "£200",
      to: "birthday-delux",
      priceFontSize: "20px",
      titleFontSize: "20px",
      features: {
        size: "large",
          time : "1.5 hours",
          maxChild: "8",
          hiddenFees : "No",
          unicorns: 1,
      }
  },
  {
    id: 1,
    title: "Special Party",
    price: "from £99",
    to: "birthday",
    priceFontSize: "18px",
    titleFontSize: "24px",
    isRibbon: true,
    features: {
        size: "small to medium",
        time: "60 mins",
        hiddenFees : "No",
        unicorns: 1,
    }
  }
]


function Component() {
  useLayoutEffect(() => {
      window.scrollTo(0, 0)
  });
  return (
    <>
      <NavbarPage navClass={""} noun={"Reindeer"} />
      <Hero ledes={heroLedes} noun={"reindeer"} event={"Christmas party"} />
      <Features
        title={"A festive reindeer party in your garden"}
        lede={"We have beautiful, adorable tiny pony reindeer that you can fuss over, pet, to make this Christmas unforgettable."}
        features={features}
        images={images}
      />
      <Services services={services} />
      <Team
        lede={"Our reindeer would love to attend your party. Each has a unique personality and distinctive look. All are calm and adorable and love being fussed and brushed."}
        focusPonySrc={"assets/images/gallery/reindeer01.png"}
        focusPonyStyle={{}}
        focusPonyName={'Rudolph'}
      />
      <Testimonials/>
      <Pricing pricings={pricing} noun="Reindeer" />
      <ContactUs/>
      <Footer/>
   </>
  );
}

export default Component