import React, { Component } from 'react';
import { Container, Button, Row, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";
import { withRouter } from 'react-router-dom'

//Import Pricing
import PricingBox from './pricing-box';

class Pricing extends Component {

    constructor(props) {
        super(props)
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(selectedPackage) {
        window.location = 'https://www.facebook.com/PonyTime.co.uk'
    }

    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="pricing">
                    <Container>
                        {/* Render section title */}
                        <SectionTitle title="Our Prices" description="£25 deposit and we're super flexible on rescheduling."/>

                        <Row className="margin-t-50">
                        {/* Render Pricing Box */}
                        {
                            this.props.pricings.map((pricing) =>
                                <PricingBox
                                    includeChildLimit={this.props.includeChildLimit}
                                    includesCrafts={this.props.includesCrafts}
                                    pricing={pricing}
                                    handleClick={this.handleClick}
                                    noun={this.props.noun}
                                />
                            )
                        }
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default withRouter(Pricing);
