import React from 'react';
import NavbarPage from "../components/Navbar/NavbarPage";
import Features from '../components/Features/features';
import Services from '../components/Services/services';
import Team from '../components/Team/Team';
import Pricing from '../components/Pricing/pricing';
import Testimonials from '../components/Testimonials/testimonials';
import ContactUs from '../components/Contact Us/contact-us';
import Footer from '../components/Footer/footer';
import Hero from '../components/Hero/hero';

import { 
    Container,
    Col,
    Row,
    Navbar,
} from "reactstrap";

const heroLedes = [
    {
      text: 'Have a unicorn at your birthday party!',
      animation: 'fade',
    },
    {
      text: "“The kids can't wait to tell everyone at school!”",
      animation: 'fade',
    },
    {
      text: 'Give your child a unique unforgettable experience',
      animation: 'fade',
    },
    {
      text: "“I love how Sarah shows how to care for Glitter”",
      animation: 'fade',
    },
    {
      text: "“All the children loved it!”",
      animation: 'fade',
    },
    {
      text: "“Absolutely perfect for my 4 year old”",
      animation: 'fade',
    },
    {
      text: 'Your child can ride and play with a real unicorn',
      animation: 'fade',
    },
    {
      text: "“Recommend for dads needing extra browny points!”",
      animation: 'fade',
    }

];

const features = [
  "Actual real unicorns that we found under a rainbow!",
  "A day to remember for years",
  "Flexible: you can reschedule at no extra cost",
  "We travel to Lincolnshire and Nottinghamshire"
]

const services =  [
  { title : "Safe as houses", description : "Sarah has over 50 years of experience and the unicorns are very well trained and calm." },
  { title : "Personalised rosettes", description : "Remember a unique day with a personalised rosette for every child showing the birthday girl/boy's name." },
  { title : "Magical ride", description : "The smaller kids can ride on our unicorns. Bigger kids of all ages can pat them! The kiddies can colour in their rosette while they wait their turn." },
  { title : "Friendly staff", description : "Sarah will solve any problem to ensure the day goes swimmingly." },
  { title : "Safe and professional", description : "Sarah has over four decades of experience with horses, and all the paperwork" },
  { title : "Easy to customize", description : "Need something extra special done? Get in touch and we can make it happen!" },
]

const pricing = [
    {
      id: 3,
      title: "ULTIMATE Birthday",
      price: "£199",
      to: "birthday-ultimate",
      priceFontSize: "24px",
      titleFontSize: "18px",
      features: {
          size: "very large",
          hiddenFees: "No",
          unicorns: 2,
      }
  },
  {
      id: 2,
      title: "DELUXE Birthday",
      price: "£149",
      to: "birthday-delux",
      priceFontSize: "20px",
      titleFontSize: "20px",
      features: {
          size: "large",
          hiddenFees : "No",
          unicorns: 1,
      }
  },
  {
    id: 1,
    title: "Special Birthday",
    price: "from £99",
    to: "birthday",
    priceFontSize: "18px",
    titleFontSize: "24px",
    isRibbon: true,
    features: {
        size: "small to medium",
        hiddenFees : "No",
        unicorns: 1,
    }
  }
]

const images = [
  {
    original: '/assets/images/gallery/head-01.jpg',
    width: 500,
  },
  {
    original: '/assets/images/gallery/head-02.jpg',
    width: 500,
  },
  {
    original: '/assets/images/gallery/pat-01.jpg',
    width: 500,
  },
  {
    original: '/assets/images/gallery/rider-04.jpg',
    width: 500,
  },
  {
    original: '/assets/images/gallery/rider-01.jpg',
    width: 500,
  },
  {
    original: '/assets/images/gallery/head-03.jpg',
    width: 500,
  },
  {
    original: '/assets/images/gallery/rider-02.jpg',
    width: 500,
  },
  {
    original: '/assets/images/gallery/side-02.jpg',
    width: 500,
  },
  {
    original: '/assets/images/gallery/side-01.jpg',
    width: 500,
  },
  {
    original: '/assets/images/gallery/standing-01.jpg',
    width: 500,
  },
  {
    original: '/assets/images/gallery/side-03.jpg',
    width: 500,
  },
];

function Component() {
  return (
    <>
      <NavbarPage navClass={""} noun={"Unicorns"} />
      <Hero ledes={heroLedes} noun={"unicorns"} event={"party"} />
      <Testimonials/>

      <Features
        title={"A unicorn party in your garden"}
        lede={"We have beautiful, calm and gentle ponies that your kids can fuss over, learn about handling, and even ride!"}
        features={features}
        images={images}
      />
      <Team
        lede={"Our unicorns would love to attend your party. Each has a unique personality and distinctive look. All are calm and great around children of all ages."}
        focusPonySrc="assets/images/team/glitter.jpg"
        focusPonyName='Gliter'
        focusPonyStyle={{filter: "brightness(1.2)"}}
      />
      <Pricing
        pricings={pricing}
        noun={"Unicorns"}
      />
      <Services services={services}/>
      <ContactUs/>
      <Footer/>
   </>
  );
}

export default Component