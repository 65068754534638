import React from 'react';
import NavbarPage from "../components/Navbar/NavbarPage";
import Features from '../components/Features/features';
import Services from '../components/Services/services';
import Team from '../components/Team/Team';
import Pricing from '../components/Pricing/pricing';
import ContactUs from '../components/Contact Us/contact-us';
import Footer from '../components/Footer/footer';
import Hero from '../components/Hero/hero';


const images = [
  {
    original: '/assets/images/gallery/head-01.jpg',
    width: 500,
  },
  {
    original: '/assets/images/gallery/head-02.jpg',
    width: 500,
  },
  {
    original: '/assets/images/gallery/side-02.jpg',
    width: 500,
  },
  {
    original: '/assets/images/gallery/side-01.jpg',
    width: 500,
  },
  {
    original: '/assets/images/gallery/standing-01.jpg',
    width: 500,
  },
];

const heroLedes = [
    {
        text: 'Unicorns are not just for kids!',
        animation: 'fade',
    },
    {
        text: 'Unicorns are for grownups too!',
        animation: 'fade',
    },
    {
        text: 'We bring unicorns to your party',
        animation: 'fade',
    },
    {
        text: 'Why should the kids have all the fun!?',
        animation: 'fade',
    },
    {
        text: 'Create a unique unforgettable experience',
        animation: 'fade',
    },
];

const features = [
  "Actual real life tiny 4 foot tall unicorn at your party!",
  "You and your friends will laugh about this for years to come",
  "Flexible: you can reschedule at no extra cost",
  "We travel to Lincolnshire and Nottinghamshire"
]

const services =  [
  { title : "Covid safe", description : "Sarah wears top quality face masks and professionally cleans the equipment before every party." },
  { title : "Personalised rosettes", description : "Remember a unique day with a personalised rosettes for you and your friends." },
  { title : "Fuss the unicorns", description : "Pet our unicorns, groom, and fuss them! Your friends can take lots of pictures to make memories." },
  { title : "Friendly staff", description : "Sarah will solve any problem to ensure the day goes swimmingly." },
  { title : "Safe and professional", description : "Sarah has over four decades of experience with horses, and all the paperwork" },
  { title : "Easy to customize", description : "Need something extra special done? Get in touch and we can make it happen!" },
]

const pricing = [
    {
      id: 3,
      title: "ULTIMATE Party",
      price: "£300",
      to: "birthday-ultimate",
      priceFontSize: "24px",
      titleFontSize: "18px",
      features: {
          size: "very large",
          time: "2 hours",
          maxChild: "8",
          hiddenFees: "No",
          unicorns: 2,
      }
  },
  {
      id: 2,
      title: "DELUXE Party",
      price: "£200",
      to: "birthday-delux",
      priceFontSize: "20px",
      titleFontSize: "20px",
      features: {
          size: "large",
          time : "1.5 hours",
          maxChild: "8",
          hiddenFees : "No",
          unicorns: 1,
      }
  },
  {
    id: 1,
    title: "Special Party",
    price: "from £99",
    to: "birthday",
    priceFontSize: "18px",
    titleFontSize: "24px",
    isRibbon: true,
    features: {
        size: "small to medium",
        time: "60 mins",
        hiddenFees : "No",
        unicorns: 1,
    }
  }
]


function Component() {
  return (
    <>
      <NavbarPage navClass={""} noun={"Unicorns"} />
      <Hero ledes={heroLedes} noun={"unicorns"} event={"party"} />
      <Features
        title={"A unicorn party in your garden"}
        lede={"We have beautiful, adorable tiny ponies that you can fuss over, pet, and groom on your special day."}
        features={features}
        images={images}
      />
      <Services services={services} />
      <Team
        lede={"Our unicorns would love to attend your party. Each has a unique personality and distinctive look. All are calm and adorable and love being fussed and brushed."}
        focusPonySrc="assets/images/team/glitter.jpg"
        focusPonyName='Gliter'
        focusPonyStyle={{filter: "brightness(1.2)"}}
      />
      <Pricing pricings={pricing} noun="unicorns" />
      <ContactUs/>
      <Footer/>
   </>
  );
}

export default Component