import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import ImageGallery from 'react-image-gallery';


export function Features(props) {
    return (
        <section className="section" id="features">
            <Container>
                <Row className="align-items-center">
                    <Col lg="5" className="order-2 order-lg-1">
                        <div className="features-box mt-5 mt-lg-0">
                            <h3>{props.title}</h3>
                            <p className="text-muted web-desc">
                                {props.lede}
                            </p>
                            <ul className="text-muted margin-t-30 features-item-list">
                                {props.features.map(item => <li className="">{item}</li>)}
                            </ul>
                            <a href="#pricing" className="btn btn-custom margin-t-30 waves-effect waves-light">See prices <i className="mdi mdi-arrow-right"></i></a>
                        </div>
                    </Col>
                    <Col lg={{size: 7, order: 2, }} xs={{order : 1}}>
                        <ImageGallery
                            lazyLoad
                            showThumbnails={false}
                            showFullscreenButton={false}
                            items={props.images}
                            autoPlay
                            showPlayButton={false}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Features;