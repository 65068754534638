import React, { Component } from 'react';
import { Container, Row } from "reactstrap";

//Import Section Title
import SectionTitle from "../common/section-title";

//Import Service Box
import ServiceBox from "./service-box";

class Services extends Component {

    render() {
        return (
            <React.Fragment>
                <section className="section bg-light" id="services">
                    <Container>
                        <Row className="">
                            {/* render service box */}
                            {
                                this.props.services.map((service) =>
                                    <ServiceBox title={service.title} icon={service.icon} description={service.description} />
                                )
                            }
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Services;