import React, { Component } from 'react';
import { Container, Row, Col, Button } from "reactstrap";
import { AvForm, AvField } from 'availity-reactstrap-validation';

//Import Section Title
import SectionTitle from "../common/section-title";

class ContactUs extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="section " id="contact">
                    <Container>
                        {/* Render section title */}
                        <SectionTitle title="Get In Touch" />

                        <Row>
                            <Col lg="12">
                                <div id="message"></div>
                                <div className="mt-4 pt-4">
                                    <p className="mt-4"><span className="h5">Animal welfare license:</span><br/> <span className="text-muted d-block mt-2">License number AWL037 as held by Sarah Tier.</span></p>
                                    <p className="mt-4"><span className="h5">Area we operate:</span><br/> <span className="text-muted d-block mt-2">Our unicorns are happy to travel up to 45 minutes from Gainsborough</span></p>
                                    <p className="mt-4"><span className="h5">Working Hours:</span><br/> <span className="text-muted d-block mt-2">9:00AM To 6:00PM, 7 days a week</span></p>

                                    <p><span className="h5">Let's chat:</span><br/><span className="text-muted d-block mt-2">If you have an questions you can chat with us on Facebook Messanger.</span></p>
                                    <a href='https://www.facebook.com/PonyTime.co.uk'>
                                        <Button className="btn-custom navbar-btn btn-rounded waves-effect waves-light"><img src="/assets/images/facebook.png" height="36" />find on Facebook</Button>
                                    </a>
                                </div>  
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default ContactUs;